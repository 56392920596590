<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <BlockColumn>
      <BlockItem :size="'1-1'">
        <BlockInfoSelect
          :title="$t('country') + ':'"
          :selected="countryChossed"
          :values="countryCodes"
          @change-value="changeCountry"
        />
      </BlockItem>
    </BlockColumn>

    <BlockInfo
      :title="$t('order_number')"
      v-model="completedAtWork.orderNo"
      @input="enable_Sign_Btn()"
    />

    <BlockInfoDatePickerCompletedAtWork
      :title="$t('first_date_signature') + ' ' + $t('completed')"
      v-model="completedAtWork.dateSignInFirst"
      :disabled="false"
    />
        <BlockInfoDatePickerCompletedAtWork
      :title="$t('second_date_signature') + ' ' + $t('completed')"
      v-model="completedAtWork.dateSignInSecond"
      :disabled="false"
    />

    <div class="completedAtWork-sectionButtons">
      <CButton
        :color="'success'"
        size="lg"
        class="completedAtWork-button"
        @click="actionEvent()"
        :disabled="disabled_CompletedAtWork_btn"
        >{{ completedAtWork_btn }}</CButton
      >
    </div>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import BlockInfoDatePickerCompletedAtWork from "../components/BlockInfoDatePickerCompletedAtWork";
import BlockInfo from "../components/BlockInfo";
import BlockInfoSelect from "../components/BlockInfoSelect";
import BlockColumn from "../components/BlockColumn";
import BlockItem from "../components/BlockItem";
import "vue2-datepicker/index.css";


export default {
  name: "Versions",
  components: {
    BlockInfo,
    BlockInfoSelect,
    BlockColumn,
    BlockItem,
    BlockInfoDatePickerCompletedAtWork,
  },
  props: {},
  data() {
    return {
      loading: false,
      disabled_CompletedAtWork_btn: false,
      completedAtWork_btn: this.$t('completed'),
      completedAtWork: {},

      toastShow: false,
      toastColor: "",
      toastMessage: "",

      countryChossed: "",
      countryCodes: [],
      country: null,
    };
  },
  computed: {},
  async created() {
    await this.loadCountryData();
  },
  methods: {
    async loadCountryData() {
      var self = this;
      self.country = localStorage.getItem("userCountry")
        ? JSON.parse(localStorage.getItem("userCountry"))
        : "";
      try {
        self.countryCodes = [self.country];
        const res = await self.$http.get("apiConsole/console/getCountries");
        var countries = res.data.countries;
        self.countryCodes = [];
        countries.map((e) => {
          self.countryCodes.push(e.code);
        });
        self.changeCountry(self.countryCodes[0]);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    formValidate() {
      const self = this;
      if (!self.completedAtWork.orderNo) {
        self.showToastError("Order number is required");
        return false;
      }
      if (
        (self.completedAtWork.dateSignInFirst &&
          !self.completedAtWork.dateSignInSecond) ||
        (!self.completedAtWork.dateSignInFirst &&
          self.completedAtWork.dateSignInSecond)
      ) {
        self.showToastError("Please complete both dates or leave it empty");
        return false;
      }
      if (
        self.completedAtWork.dateSignInFirst >
        self.completedAtWork.dateSignInSecond
      ) {
        self.showToastError("First date must be earlier than second");
        return false;
      }
      return true;
    },
    changeCountry(country) {
      var self = this;
      self.country = country;
      self.countryChossed = country;
    },
    enable_Sign_Btn() {
      const self = this;
      (self.completedAtWork_btn = this.$('completed')),
        (self.disabled_CompletedAtWork_btn = false);
    },

    actionEvent() {
      const self = this;
      if (self.formValidate()) {
        self.disabled_CompletedAtWork_btn = true;
        self.showToastInfo("The delivery was signed");
        self.completedAtWork_btn = "Signed Delivery";
        self.sendCompletedAtWork(self.completedAtWork);
      }
    },

    async sendCompletedAtWork(sendCompletedAtWork) {
      const self = this;
      try {
        self.completedAtWork_btn = "Signed Delivery";
        console.log(sendCompletedAtWork);
        const res = await self.$http.post(
          "apiConsole/console/completedAtWork",
          {
            completedAtWork: sendCompletedAtWork,
          }
        );
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res);
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },

    showToastError(msg) {
      const self = this;

      self.toastShow = true;
      self.toastColor = "danger";
      if (msg) {
        self.toastMessage = msg;
      } else {
        self.toastMessage = "Error";
      }

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },

    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
  },
};
</script>

<style scoped>
.completedAtWork-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.completedAtWork-button {
  margin-left: 1em;
  font-size: 14px;
}
</style>